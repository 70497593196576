import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Container from "./container";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/footer.module.css";

const query = graphql`
  query FooterQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      footerQuote {
        author
        text
      }
    }
    footernav: allSanityNav(filter: { title: { eq: "Footer Navigation" } }) {
      edges {
        node {
          navItems {
            _key
            blank
            url
            text
          }
        }
      }
    }
    bottomlinks: allSanityNav(filter: { title: { eq: "Bottom Links" } }) {
      edges {
        node {
          navItems {
            _key
            blank
            url
            text
          }
        }
      }
    }
  }
`;

const Footer = () => {

  return(
  <StaticQuery
    query={query}
    render={data => (
      <footer className={styles.root}>
        <Container>
          <div className={styles.top}>
            <div className="grid">
              <div className="col-12 col-md-7 col-xl-8">
                {data.site.footerQuote && data.site.footerQuote.text && (
                  <div className="content-sm">
                    <blockquote className={cn("h1", styles.quote)}>{data.site.footerQuote.text}</blockquote>
                    {data.site.footerQuote.author && (<span className={styles.author}>&#8212; {data.site.footerQuote.author}</span>)}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-5 col-xl-4">
                {data && data.footernav.edges.map(({node: footernav}) => (
                  <nav className={styles.footerNavBlock}>
                    <ul className={styles.footerNav}>
                      {footernav.navItems.map(navItems => (
                        <li key={navItems._key}>
                          {navItems.blank ? (
                            <a href={navItems.url} target="_blank" rel="noreferrer">{navItems.text}</a>
                          ) : (
                            <Link to={navItems.url}>{navItems.text}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className="grid">
              <div className={cn("col-12 col-md-7 col-xl-8", styles.copyrightCol)}>
                {data.site.title && (
                  <div className={styles.copyright}>
                    &copy; {new Date().getFullYear()} {data.site.title}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-5 col-xl-4">
                {data && data.bottomlinks.edges.map(({node: bottomlinks}) => (
                  <nav>
                    <ul className={styles.bottomLinks}>
                      {bottomlinks.navItems.map(navItems => (
                        <li key={navItems._key}>
                          {navItems.blank ? (
                            <a href={navItems.url} className={navItems.class} target="_blank" rel="noreferrer">{navItems.text}</a>
                          ) : ( 
                            <Link to={navItems.url} className={navItems.class}>{navItems.text}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </footer>
    )}
  />
)};

export default Footer;
