import React from "react";

const External = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 15.604V18.1111C23 19.4062 22.4859 20.6483 21.5706 21.5649C20.6553 22.4815 19.4136 22.9976 18.118 23H5.88198C4.5864 22.9976 3.34466 22.4815 2.42938 21.5649C1.5141 20.6483 1.00001 19.4062 1.00001 18.1111V5.88885C0.998831 5.24726 1.12424 4.61174 1.36905 4.01864C1.61386 3.42555 1.97331 2.88655 2.42676 2.43246C2.8802 1.97836 3.41873 1.61808 4.01162 1.37228C4.6045 1.12648 5.24013 0.999999 5.88198 1H8.39005" stroke="#33BC52" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M23 10V1H14" stroke="#33BC52" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M23 1L9 15" stroke="#33BC52" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
</svg>

);

export default External;
