import { format, isFuture } from "date-fns";

export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(new Date(publishedAt));
}

export function getNewsUrl(category, slug) {
  return `/news-updates/${category.slug.current || category.slug}/${slug.current || slug}`;
}

export function getCatUrl(slug) {
  return `/news-updates/${slug.current || slug}`;
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function toPlainText(blocks) {
  if (!blocks) {
    return "";
  }
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map((child) => child.text).join("");
    })
    .join("\n\n");
}

export function seoUrl(string) {
  //Lower case everything
  string = string.toLowerCase();
  //Make alphanumeric (removes all other characters)
  string = string.replace(/[^a-z0-9_\s-]/g, "");
  //Clean up multiple dashes or whitespaces
  string = string.replace(/[\s-]+/g, " ");
  //Convert whitespaces and underscore to dash
  string = string.replace(/[\s_]/g, "-");
  return string;
}

export function phoneUrl(string) {
  //Lower case everything
  string = string.toLowerCase();
  //Make alphanumeric (removes all other characters)
  string = string.replace(/[^0-9+_\s-]/g, "");
  //Clean up multiple dashes, whitespaces and understores and Convert them to dash
  string = string.replace(/[_\s-]+/g, "");
  return string;
}
