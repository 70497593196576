import React from "react";

const Scroll = () => (
<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="1" width="62" height="62" rx="31" stroke="#33BC52" strokeWidth="2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M41.7974 32.5755L33.8505 40.6798V20H32.1495V40.6798L24.2034 32.5746L23 33.801L33 44L43 33.8019L41.7974 32.5755Z" fill="#33BC52"/>
</svg>
);

export default Scroll;
