import React from "react";
import ArrowRight from "./arrow-right";
import ArrowLeft from "./arrow-left";
import Logo from "./logo";
import External from "./external";
import Scroll from "./scroll";
import Close from "./close";

function Icon(props) {
  switch (props.symbol) {
    case "arrow-right":
      return <ArrowRight />;
    case "arrow-left":
      return <ArrowLeft />;
    case "logo":
      return <Logo />;
    case "external":
      return <External />;
    case "scroll":
      return <Scroll />;
    case "close":
      return <Close />;
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;
