// extracted by mini-css-extract-plugin
export var root = "footer-module--root--Qo4MQ";
export var top = "footer-module--top--AESbe";
export var quote = "footer-module--quote--jjE8v";
export var author = "footer-module--author--bhVho";
export var footerNavBlock = "footer-module--footerNavBlock--zY-Zk";
export var footerNav = "footer-module--footerNav--pYoH3";
export var bottom = "footer-module--bottom--1mAt6";
export var copyright = "footer-module--copyright--5Ut3e";
export var bottomLinks = "footer-module--bottomLinks--JCRys";
export var copyrightCol = "footer-module--copyrightCol--viWkY";