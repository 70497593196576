import React from "react";

const ArrowLeft = () => (
<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.04441 15.0379L2.62845 8.68039L19 8.68039L19 7.31961L2.62845 7.31961L9.0451 0.962748L8.07419 -4.77582e-07L-3.49691e-07 8L8.07351 16L9.04441 15.0379Z" fill="#33BC52"/>
</svg>

);

export default ArrowLeft;
