import React from "react";

const Close = () => (
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="12.6504" y="0.950195" width="2" height="17" transform="rotate(45 12.6504 0.950195)" fill="#33BC52"/>
<rect x="14.1504" y="13.1465" width="2" height="17" transform="rotate(135 14.1504 13.1465)" fill="#33BC52"/>
</svg>
);

export default Close;
