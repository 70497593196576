import { PortableText as BasePortableText } from "@portabletext/react";
import React from "react";
import clientConfig from "../../client-config";
import { Figure } from "./figure";
import { Button } from "./button";

const components = {
  types: {
    mainImage: ({value}) => <Figure node={value} />,
    button: ({value}) => <Button node={value} />,
  },

  marks: {
    link: ({value, children}) => {
      const target = (value?.blank) ? '_blank' : undefined
      return (
        <a href={value?.url} target={target} rel={target === '_blank' ? 'noindex nofollow' : undefined}>
          {children}
        </a>
      )
    },
  }
};


const PortableText = ({ blocks }) => (
  <div className="entry-content">
    <BasePortableText
      value={blocks}
      components={components}
      {...clientConfig.sanity}
    />
  </div>
);

export default PortableText;
