import React from "react";
import { cn } from "../lib/helpers";
import { Link } from "gatsby";
import Icon from "./icon";

export const Button = ({ node }) => {
  if (!node || !node.url) {
    return null;
  }
  return (
    <>
      {node.blank ? (
        <a href={node.url} className={cn("btn pt-btn", node.primaryButton && "primary", node.noArrow && "center")} target="_blank" rel="noreferrer">
          {node.text}
          {!node.noArrow && (<Icon symbol="arrow-right" />)}
        </a>
      ) : (
        <Link to={node.url} className={cn("btn pt-btn", node.primaryButton && "primary", node.noArrow && "center")}>
          {node.text}
          {!node.noArrow && (<Icon symbol="arrow-right" />)}
        </Link>
      )}
    </>
  );
};