import { Link, StaticQuery, graphql } from "gatsby";
import React, { useLayoutEffect, useState, useRef } from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import Container from "./container";
import { useCookies } from 'react-cookie';
import PortableText from "./portableText";
import { Helmet } from 'react-helmet';

import * as styles from "../styles/components/header.module.css";

const query = graphql`
  query HeaderQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawHeaderNotification
      headerUtility {
        blank
        primaryButton
        noArrow
        text
        url
      }
    }
    mainnav: allSanityNav(filter: { title: { eq: "Main Navigation" } }) {
      edges {
        node {
          navItems {
            _key
            blank
            url
            text
          }
        }
      }
    }
  }
`;

const Header = (props) => {

  const {
    dark
  } = props;

  const [showNav, setShowNav] = useState(false);
  const [hideNfBar, setHideNfBar] = useState(true);
  const [showFading, setShowFading] = useState(false);
  const [cookies, setCookie] = useCookies(['hideNotificationBar']);

  // Header element/height
  const headerRef = useRef(null);
  const navRef = useRef(null);
  const nbRef = useRef(null);
  let headerHeight;
  let nbHeight;

  function handleShowNav() {
    setShowNav(true);

    if(nbRef.current) {
      nbHeight = nbRef.current.clientHeight;
      navRef.current.style.height = 'calc(100% - ' + nbHeight + 'px)';
    }
    else {
      navRef.current.style.height = '100vh';
    }
  }

  function handleHideNav() {
    const mobile = window.matchMedia(`(max-width: 959px)`).matches;

    if(mobile) {
      setShowNav(false);
      navRef.current.style.height = 0;
    }
  }

  function handleHideNotificationBar() {
    setCookie('hideNotificationBar', true, {maxAge: 604800});
    setHideNfBar(true);
  }

  function handleSetHideNfBar() {
    if(cookies.hideNotificationBar === undefined) {
      setHideNfBar(false);
    }
  }

  function updateNavHeight() {
    const mobile = window.matchMedia(`(max-width: 959px)`).matches;

    if(mobile) {
      if(nbRef.current) {
        nbHeight = nbRef.current.clientHeight;
        navRef.current.style.top = nbHeight + 'px';
        if(showNav) {
          navRef.current.style.height = 'calc(100% - ' + nbHeight + 'px)';
        }
      }
      else {
        navRef.current.style.top = 0;
        if(showNav) {
          navRef.current.style.height = '100vh';
        }
      }
    }
    else {
      navRef.current.style.top = '';
      navRef.current.style.height = '';
    }
  }

  function updatePageHeaderHeight() {
    const elem = document.getElementById("page-header");

    if(elem) {
      if(nbRef.current) {
        nbHeight = nbRef.current.clientHeight;
        elem.style.minHeight = 'calc(100vh - ' + nbHeight + 'px)';
      }
      else {
        elem.style.minHeight = '100vh';
      }
    }
  }

  function handleScroll() {
    const isScrolled = window.pageYOffset > 50;
    
    if (isScrolled !== showFading) {
      setShowFading(!showFading);
    }
  }

  useLayoutEffect(() => {
    handleSetHideNfBar();
    handleScroll();

    let checkExist = setInterval(function() {
      if (headerRef.current) {
        updateNavHeight();
        updatePageHeaderHeight();
        clearInterval(checkExist);
      }
    }, 100); // check every 100ms

    window.addEventListener('resize', updateNavHeight);
    window.addEventListener('resize', updatePageHeaderHeight);
    document.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', updateNavHeight);
      window.removeEventListener('resize', updatePageHeaderHeight);
      document.removeEventListener('scroll', handleScroll);
    }
  });

  return (
  <StaticQuery
    query={query}
    render={data => (
      <header className={cn(styles.root, dark && styles.dark, showNav && styles.menuOpen)} data-scroll={showFading ? undefined : 'top'} ref={headerRef}>

          <Helmet>
            <body className={showNav ? 'menu-open' : undefined} />
          </Helmet>

          <div className={styles.main}>
            <Container>
              <div className="grid align-center justify-between">

                <div className="col-auto">
                  <div className={styles.branding}>
                    <Link to="/"><Icon symbol="logo" /></Link>
                  </div>
                </div>

                <div className="col-auto">
                  <div className={cn(styles.navWrap, showNav && styles.active)} ref={navRef}>

                    {data && data.mainnav.edges.map(({node: mainnav}) => (
                      <nav className={styles.mainNavBlock}>
                        <ul className={styles.mainNav}>
                          {mainnav.navItems.map(navItems => (
                            <li key={navItems._key}>
                              {navItems.blank ? (
                                <a href={navItems.url} target="_blank" rel="noreferrer" onClick={handleHideNav}>{navItems.text}</a>
                              ) : (
                                <Link to={navItems.url} onClick={handleHideNav}>{navItems.text}</Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      </nav>
                    ))}

                    {data.site.headerUtility && data.site.headerUtility.url && (
                      <div className={cn(styles.utility, styles.dHide)}>
                        {data.site.headerUtility.blank ? (
                          <a href={data.site.headerUtility.url} className={cn("btn", data.site.headerUtility.primaryButton && "primary", data.site.headerUtility.noArrow && "center", styles.utilBtn)} target="_blank" rel="noreferrer">
                              {data.site.headerUtility.text}
                              {!data.site.headerUtility.noArrow && (<Icon symbol="arrow-right" />)}
                          </a>
                        ) : (
                          <Link to={data.site.headerUtility.url} className={cn("btn", data.site.headerUtility.primaryButton && "primary", data.site.headerUtility.noArrow && "center", styles.utilBtn)}>
                            {data.site.headerUtility.text}
                            {!data.site.headerUtility.noArrow && (<Icon symbol="arrow-right" />)}
                          </Link>
                        )}
                      </div>
                    )}

                  </div>
                </div>

                <div className="col-auto">

                  {data.site.headerUtility && data.site.headerUtility.url && (
                    <div className={cn(styles.utility, styles.mHide)}>
                      {data.site.headerUtility.blank ? (
                          <a href={data.site.headerUtility.url} className={cn("btn", data.site.headerUtility.primaryButton && "primary", data.site.headerUtility.noArrow && "center", styles.utilBtn)} target="_blank" rel="noreferrer">
                              {data.site.headerUtility.text}
                              {!data.site.headerUtility.noArrow && (<Icon symbol="arrow-right" />)}
                          </a>
                        ) : (
                          <Link to={data.site.headerUtility.url} className={cn("btn", data.site.headerUtility.primaryButton && "primary", data.site.headerUtility.noArrow && "center", styles.utilBtn)}>
                            {data.site.headerUtility.text}
                            {!data.site.headerUtility.noArrow && (<Icon symbol="arrow-right" />)}
                          </Link>
                        )}
                    </div>
                  )}

                  <button
                    className={cn(styles.menuToggle, showNav && styles.active, styles.dHide)}
                    onClick={showNav ? handleHideNav : handleShowNav}
                  >
                    {showNav ? "Close" : "Menu"}
                  </button>

                </div>
              </div>
            </Container>
          </div>

          {data.site._rawHeaderNotification && !hideNfBar && (
            <div className={styles.notificationBar} ref={nbRef}>
              <Container>
                <div className={styles.nbInner}>
                  <PortableText blocks={data.site._rawHeaderNotification} />
                  <button onClick={handleHideNotificationBar}>
                    <span className="screen-reader-text">Close</span>
                    <Icon symbol="close" />
                  </button>
                </div>
              </Container>
            </div>
          )}

      </header>
    )}
  />
)};

export default Header;
